<template>
  <div class="index">
    <div class="studentInfo">
      <div class="imgBox" @click="personal">
        <img src="../../../assets/img/studyImg.png" alt="">
      </div>
      <div style="margin-top:3px">
        <p>老师姓名</p>
        <p style="margin-top:8px">{{teacherName}}</p>
      </div>
    </div>
    <div class="studyBox">
      <img src="../../../assets/img/indexImg.png" alt="">
    </div>
    <p style="margin: 30px 0 10px;font-weight:bold;">技能学习</p>
    <ul>
        <li @click="linkUrl('teaevaluateStudy')">
            <div>
                <img src="../../../assets/img/indexListImg.png" alt="">
            </div>
            <p>操作评价</p>
        </li>
        <li @click="linkUrl('teaevaluateRecord')">
            <div>
                <img src="../../../assets/img/indexListImg2.png" alt="">
            </div>
            <p>评价记录</p>
        </li>
        <li @click="linkUrl('teacherwork')">
            <div>
                <img src="../../../assets/img/indexListImg2.png" alt="">
            </div>
            <p>视频作业</p>
        </li>
    </ul>

  </div>
</template>

<script>
import Cookies from 'js-cookie';
export default {
  data() {
    return {
        teacherName: "",
    }
  },
  methods: {
    linkUrl(url) {
      this.$router.push({ name: url });
    },
    personal() {
      this.$router.push({ name: "teacherpersonal" });
    }
  },
  mounted() {
      this.teacherName = Cookies.get("teacherName");
      //this.cookies.kkSet("StudentGroupID", "8409c4c4-e1f8-40b7-b760-95b96ece83ac");
  },
}
</script>

<style>
.index {
  position: relative;
  padding: 20px;
}
.index .studentInfo {
  display: flex;
}
.index .imgBox {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 20px;
}
.index img {
  width: 100%;
  height: 100%;
}
.index .studyBox {
  width: 100%;
  height: 204px;
  border: 1px solid #bbb;
  margin-top: 12px;
}
.index ul {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.index ul li {
  width: 45%;
  height: 180px;
  border: 1px solid #bbb;
  margin: 0 2.5%;
  margin-top: 10px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.4);
}
.index ul li div {
  width: 100%;
  height: 100px;
  margin-top: 20px;
}
.index ul li p {
  margin-top: 20px;
  margin-left: 12px;
}
</style>